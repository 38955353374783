export const wizardSteps = [
  { step: 1, label: 'Select Node Type' },
  { step: 2, label: 'Select Your Blockchain' },
  { step: 3, label: 'Choose Cloud Provider' },
  { step: 4, label: 'Configure Node Environment' },
  { step: 5, label: 'Summary' },
  { step: 6, label: 'Payment' },
]

export const apiErrorMessage = 'Unexpected API response'
