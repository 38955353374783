import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import { CSpinner } from '@coreui/react'
import loaderGif from './assets/images/creative-nobg.gif'
import { isAuthenticated, setGuestSession, setUserSession } from './services/auth'

const loading = (
  <div className="pt-3 text-center bg-light min-vh-100 justify-content-center align-items-center d-flex">
    {/* <CSpinner color="primary" variant="grow" /> */}
    <img src={loaderGif} alt="Loader..." />
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/auth/Login'))
const Signup = React.lazy(() => import('./views/pages/auth/Signup'))
const SignupOTPVerification = React.lazy(() => import('./views/pages/auth/SignupOTPVerification'))
const TfaOTPVerification = React.lazy(() => import('./views/pages/auth/tfa/TfaOTPVerification'))
const SignupSuccess = React.lazy(() => import('./views/pages/auth/SignupSuccess'))

const ForgotPassword = React.lazy(() => import('./views/pages/auth/ForgotPassword'))
const ResetPasswordVerification = React.lazy(() =>
  import('./views/pages/auth/ResetPasswordVerification'),
)
const PasswordResetSuccess = React.lazy(() => import('./views/pages/auth/PasswordResetSuccess'))

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  if (!isAuthenticated()) {
    setGuestSession()
  }
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
          <Route
            exact
            path="/signup"
            name="Signup Page"
            render={(props) => <Signup {...props} />}
          />
          <Route
            exact
            path="/otp-verification/:token"
            name="OTP Verification"
            render={(props) => <SignupOTPVerification {...props} />}
          />
          <Route
            exact
            path="/two-factor-authentication/:token"
            name="Two Factor Authentication"
            render={(props) => <TfaOTPVerification {...props} />}
          />
          <Route
            exact
            path="/registration-success"
            name="Registration Success"
            render={(props) => <SignupSuccess {...props} />}
          />
          <Route
            exact
            path="/forgot-password"
            name="Forgot Password"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path="/reset-password/:token"
            name="Reset Password"
            render={(props) => <ResetPasswordVerification {...props} />}
          />
          <Route
            exact
            path="/password-reset-success"
            name="Password Reset Success"
            render={(props) => <PasswordResetSuccess {...props} />}
          />
          <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
          <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}

export default App
