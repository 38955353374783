import { ENCR_KEY } from './config'
import React from 'react'
import moment from 'moment'
const CryptoJS = require('crypto-js')

export const encryptData = (data) => {
  try {
    const encTxt = CryptoJS.AES.encrypt(JSON.stringify(data), ENCR_KEY).toString()
    return encTxt.replace(/\//g, 'DoooD')
  } catch (error) {
    console.log(' encode error ', error)
  }
}

export const decryptData = (ciphertext) => {
  if (!ciphertext) return {}
  const newCtext = ciphertext.replace(/DoooD/g, '/')
  const bytes = CryptoJS.AES.decrypt(newCtext, ENCR_KEY)
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
}

export const encryptId = (data) => {
  try {
    const encTxt = CryptoJS.AES.encrypt(data, ENCR_KEY).toString()
    return encTxt.replace(/\//g, 'DoooD')
  } catch (error) {
    console.log(' encode error ', error)
  }
}

export const decryptId = (ciphertext) => {
  if (!ciphertext) return {}
  const newCtext = ciphertext.replace(/DoooD/g, '/')
  const bytes = CryptoJS.AES.decrypt(newCtext, ENCR_KEY)
  return bytes.toString(CryptoJS.enc.Utf8)
}

export const encode = (myString) => {
  // PROCESS
  const encodedWord = CryptoJS.enc.Utf8.parse(myString) // encodedWord Array object
  const encoded = CryptoJS.enc.Base64.stringify(encodedWord) // string: 'NzUzMjI1NDE='
  return encoded
}

export const decode = (encoded) => {
  // PROCESS
  const encodedWord = CryptoJS.enc.Base64.parse(encoded) // encodedWord via Base64.parse()
  const decoded = CryptoJS.enc.Utf8.stringify(encodedWord) // decode encodedWord via Utf8.stringify() '75322541'
  return decoded
}

export const trimString = (message, length = 55) => {
  if (message && message.length > length) {
    return message.substring(0, length) + '...'
  } else return message
}

export const getCurrentTime = (date) => {
  let currentDate = new Date(date)

  let day = new String(currentDate.getDate())

  let mon = new String(currentDate.getMonth() + 1) //January is 0!
  let yr = currentDate.getFullYear()

  let hr = new String(currentDate.getHours())
  let min = new String(currentDate.getMinutes())
  let sec = new String(currentDate.getSeconds())

  if (mon.length < 2) {
    mon = '0' + mon
  }
  if (day.length < 2) {
    day = '0' + day
  }
  if (hr.length < 2) {
    hr = '0' + hr
  }
  if (min.length < 2) {
    min = '0' + min
  }
  if (sec.length < 2) {
    sec = '0' + sec
  }

  // let datetime =
  //   currentDate.getDate() +
  //   '-' +
  //   (currentDate.getMonth() + 1) +
  //   '-' +
  //   currentDate.getFullYear() +
  //   ' ' +
  //   currentDate.getHours() +
  //   ':' +
  //   currentDate.getMinutes() +
  //   ':' +
  //   currentDate.getSeconds()

  // return datetime
  return moment(currentDate).format('DD MMM YYYY')
  // return day + '-' + mon + '-' + yr + ' ' + hr + ':' + min + ':' + sec
}

export const renderStatus = (status) => {
  switch (status) {
    case 'running':
      return (
        <>
          <div className="running" /> Running
        </>
      )
    case 'passed':
      return (
        <>
          <div className="running" /> Running
        </>
      )

    case 'pending':
      return (
        <>
          <div className="processing" /> Initializing
        </>
      )
    case 'terminated':
      return (
        <>
          <div className="terminated" /> Terminated
        </>
      )
    case 'stopped':
      return (
        <>
          <div className="terminated" /> Stopped
        </>
      )
    case 'stopping':
      return (
        <>
          <div className="terminated" /> Stopping
        </>
      )
    case 'shutting-down':
      return (
        <>
          <div className="terminated" /> Shutting Down
        </>
      )

    default:
      return (
        <>
          <div className="processing" /> Initializing
        </>
      )
  }
}
export const getArraysIntersection = (a1, a2) => {
  return a1.filter((n) => {
    return a2.indexOf(n) !== -1
  })
}
