import axios from 'axios'
import { apiErrorMessage } from 'src/helpers/constants'
import { ACCESS_TOKEN, API_BASE_PATH } from '../../helpers/config'

export const publicInstance = axios.create({
  baseURL: API_BASE_PATH,
})

// with access token
publicInstance.interceptors.request.use(
  (request) => {
    request.headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      accessToken: ACCESS_TOKEN,
    }
    return request
  },
  (error) => Promise.reject(error),
)
// failure handling
publicInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return apiErrorMessage

    // Promise.reject(error)
  },
)
