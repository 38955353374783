export const API_BASE_PATH = `${process.env.REACT_APP_BASE_PATH}/api`
export const STORAGE_BASE_PATH = `${process.env.REACT_APP_BASE_PATH}/invoice`

export const BACKEND_BASE_PATH = `${process.env.REACT_APP_BASE_PATH}`

export const ACCESS_TOKEN = process.env.REACT_APP_API_ACCESS_TOKEN
export const GUEST_TOKEN = process.env.REACT_APP_API_GUEST_TOKEN

export const AUTH_SESSION_NAME = 'userSession'
export const TFA_SESSION_NAME = 'tfaSession'

export const ENCR_KEY = process.env.REACT_APP_ENCR_KEY

export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_APP_CLIENT_ID
export const GITHUB_CLIENT_ID = process.env.REACT_APP_GITHUB_CLIENT_ID
export const GITHUB_CLIENT_SECRET = process.env.REACT_APP_GITHUB_CLIENT_SECRET
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID
export const CHECKOUT_PK = process.env.REACT_APP_CHECKOUT_PUBLIC_KEY
