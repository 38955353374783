import axios from 'axios'
import { clearUserSession, getUserSession } from '../auth/index.js'
import { ACCESS_TOKEN, API_BASE_PATH } from '../../helpers/config'

export const privateInstance = axios.create({
  baseURL: API_BASE_PATH,
})

// with access token
privateInstance.interceptors.request.use(
  (request) => {
    request.headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      accessToken: ACCESS_TOKEN,
      Authorization: `${getUserSession().authToken}`,
      // authToken: getUserSession().authToken || null,
    }
    return request
  },
  (error) => Promise.reject(error),
)
// failure handling
privateInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (401 === error.response.status) {
      const userData = getUserSession()
      if (userData && userData.role !== 3) {
        // clearUserSession()
        // window.location.href = '/'
      }
    }
    if (403 === error.response.status) {
      // window.location.href = '/'
    }

    Promise.reject(error)
  },
)
