import { publicInstance } from '../axios'
import {
  AUTH_SESSION_NAME,
  GITHUB_CLIENT_ID,
  GITHUB_CLIENT_SECRET,
  GUEST_TOKEN,
  TFA_SESSION_NAME,
} from '../../helpers/config'
import { apiErrorMessage } from 'src/helpers/constants'
import axios from 'axios'
import { decryptData, encryptData } from 'src/helpers/helperFunctions'

export const isAuthenticated = () => {
  const userSession = getUserSession()
  if (userSession && userSession.authToken) {
    return true
  }
  return false
}

export const haveAccess = (group) => {
  let userSession = localStorage.getItem(AUTH_SESSION_NAME)

  if (userSession) {
    userSession = JSON.parse(userSession)
    if (group.includes(userSession.role)) {
      return true
    } else return false
  }

  return false
}
export const getUserSession = () => {
  const userSession = localStorage.getItem(AUTH_SESSION_NAME)
  if (userSession) return JSON.parse(userSession)
  return {}
}
export const setUserSession = (data) => {
  localStorage.setItem(AUTH_SESSION_NAME, JSON.stringify(data))

  return {}
}
export const setGuestSession = () => {
  const data = {
    authToken: GUEST_TOKEN,
    // authToken:
    //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjMsImV4cCI6MTY2NTkyMjk1MywiaWF0IjoxNjY1OTE5MzUzfQ',
    couponEnabled: false,
    email: 'guest@guestsession.com',
    id: -1,
    role: 3,
    userName: 'Guest',
  }

  localStorage.setItem(AUTH_SESSION_NAME, JSON.stringify(data))

  return {}
}
export const clearUserSession = () => {
  localStorage.removeItem(AUTH_SESSION_NAME)
}

export const loginAction = async (formData) => {
  const submitData = {
    email: formData.username,
    password: formData.password,
  }
  return await publicInstance
    .post('/login', submitData)
    .then((res) => {
      if (res.status === 200) {
        const { status } = res.data
        if (status === 200) {
          const { token, user } = res.data
          const authData = {
            authToken: token,
            email: user.email,
            userName: user.first_name,
            id: user.id,
            role: user.is_superuser ? 2 : user.role || 1,
            couponEnabled: user.usr_coupon_enabled,
          }
          if (user.is_twofactor && user.tfaSecret) {
            const tempAuth = {
              ...authData,
              tfaSecret: user.tfaSecret,
            }
            setTfaSession(tempAuth)
            return 302
          }
          setUserSession(authData)
          return 200
        } else if (status === 301) {
          return status
        } else {
          const { message } = res.data
          return message
        }
      } else {
        return apiErrorMessage
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 403) {
        return error.response.data.detail || 'Username or Password is incorrect'
      } else {
        return apiErrorMessage
      }
      // return Promise.reject(error)
    })
}
export const registerAction = async (formData) => {
  const submitData = {
    first_name: formData.firstName,
    last_name: formData.lastName,
    email: formData.email,
    password: formData.password,
    confirmPassword: formData.confirmPassword,
  }

  return await publicInstance
    .post('register', submitData)
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.user
      } else {
        return res.data.message
      }
    })
    .catch((error) => {
      return apiErrorMessage
    })
}
export const verifyOtpAction = async (formData) => {
  const submitData = {
    otp: `${formData.otp1}${formData.otp2}${formData.otp3}${formData.otp4}${formData.otp5}`,
    email: formData.email,
  }
  // console.log('otp submit data: ', submitData)
  // return 200

  return await publicInstance
    .post('emailverify', submitData)
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.status
      } else {
        return res.data.message
      }
    })
    .catch((error) => {
      return apiErrorMessage
    })
}

export const logoutAction = async (history) => {
  localStorage.clear()
  return true
  // return publicInstance
  //   .get('/logout')
  //   .then((res) => {
  //     if (res.status === 200) {
  //       localStorage.clear()
  //       return 1
  //     } else {
  //       return 0
  //     }
  //   })
  //   .catch((error) => {
  //     return '0'
  //   })

  // history.push("/");
}

export const forgotPasswordAction = async (formData) => {
  return await publicInstance
    .post('passwordresetrequest', formData)
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.status
      } else {
        return res.data.message
      }
    })
    .catch((error) => {
      return apiErrorMessage
    })
}

export const resetPasswordAction = async (formData) => {
  const submitData = {
    otp: `${formData.otp1}${formData.otp2}${formData.otp3}${formData.otp4}${formData.otp5}`,

    password: formData.password,
    email: formData.email,
  }

  return await publicInstance
    .post('resetpassword', submitData)
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.status
      } else {
        return res.data.message
      }
    })
    .catch((error) => {
      return apiErrorMessage
    })
}
export const resendOtpAction = async (formData) => {
  return await publicInstance
    .post('resendotp', formData)
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.status
      } else {
        return res.data.message
      }
    })
    .catch((error) => {
      return apiErrorMessage
    })
}
export const fetchGithubUser = async (token) => {
  const header = { 'Access-Control-Allow-Origin': '*' }
  const postData = {
    client_id: GITHUB_CLIENT_ID,
    client_secret: GITHUB_CLIENT_SECRET,
    code: token,
    redirect_uri: 'http://localhost:3000/',
  }
  return await axios
    .post(`https://github.com/login/0auth/access_token`, postData, { headers: header })
    .then((res) => {
      if (res) {
        return res
      } else {
        return res
      }
    })
    .catch((error) => {
      return apiErrorMessage
    })
}

export const socialRegisterAction = async (formData) => {
  return await publicInstance
    .post('sociallogin', formData)
    .then((res) => {
      if (res.data.status === 200) {
        return res.data
      } else {
        return res.data.message
      }
    })
    .catch((error) => {
      return apiErrorMessage
    })
}

export const fetchGithubUserTest = async (token) => {
  const header = { 'Access-Control-Allow-Origin': '*' }
  const postData = {
    client_id: GITHUB_CLIENT_ID,
    client_secret: GITHUB_CLIENT_SECRET,
    code: token,
    redirect_uri: 'http://localhost:3000/',
  }
  return await axios
    .get(`https://github.com/login/oauth/authorize`, { headers: header })
    .then((res) => {
      if (res) {
        return res
      } else {
        return res
      }
    })
    .catch((error) => {
      return apiErrorMessage
    })
}

export const getTfaSession = () => {
  const tfaSessionStr = localStorage.getItem(TFA_SESSION_NAME)
  if (tfaSessionStr) {
    const decObj = decryptData(tfaSessionStr)
    return decObj
  }
  return {}
}
export const setTfaSession = (data) => {
  const encData = encryptData(data)
  localStorage.setItem(TFA_SESSION_NAME, encData)

  return {}
}
export const clearTfaSession = () => {
  localStorage.removeItem(TFA_SESSION_NAME)
}
